import React from "react"
import PageLayout from "../components/logic/PageLayout"

const NotFoundPage = () => (
  <PageLayout>
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </PageLayout>
)

export default NotFoundPage
